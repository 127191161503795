
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    publicName: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    publicKey: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    publicValue: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    validateCode: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    systemFlag: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    status: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    publicType: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
}
export default{
    mainRules
}